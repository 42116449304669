import Alert from './src/alert.js'
import Collapse from './src/collapse'
import Modal from './src/modal'
import Tab from './src/tab'
import Tooltip from './src/tooltip'
import Form from './src/form'
import CheckAll from './src/check-all'
import CheckDisabled from './src/check-disabled'
import InputDots from './src/input-dots'
import Toggle from './src/toggle'
import Loading from './src/loading'
import LabelFor from './src/label-for'
import ScrollMove from './src/scroll-move'
import Table from './src/table'
import { initialize, setLayout } from './src/initialize'

export default {
  Alert,
  Collapse,
  Modal,
  Tab,
  Tooltip,
  Form,
  CheckAll,
  CheckDisabled,
  InputDots,
  Toggle,
  LabelFor,
  Loading,
  ScrollMove,
  Table,
  initialize,
  setLayout
}
