/* eslint-disable unicorn/prefer-event-key */
import { makeArray, getUID } from './util/index'
import CheckAll from './check-all'
import Collapse from './collapse'
import LabelFor from './label-for'
import Tab from './tab'
import Tooltip from './tooltip'
import Form from './form'
import Table from './table'
import ScrollMove from './scroll-move'
import checkedModal from './checked-modal'
import SelectorEngine from './dom/selector-engine'
import EventHandler from './dom/event-handler'

// 사이드 메뉴
(function () {
  // 가이드 파일 제외
  if (/^\/docs|\/html.html/.test(window.location.pathname)) {
    return
  }

  const sideEl = document.querySelector('.l-side')
  const bodyEl = document.querySelector('body')
  const closeButton = document.querySelector('.l-side__button--close')
  const openButton = document.querySelector('.l-head__side')
  const mainOpenButton = document.querySelector('.fb-main-top__button')

  function hide() {
    bodyEl.classList.remove('has-l-side')
    sideEl.classList.remove('is-active')
    if (document.body.classList.contains('main')) {
      if (mainOpenButton) {
        mainOpenButton.focus()
      }
    } else {
      openButton.focus()
    }

    function listener() {
      sideEl.style.display = 'none'
    }

    sideEl.addEventListener('transitionend', listener)
    setTimeout(() => {
      sideEl.removeEventListener('transitionend', listener)
    }, 400)
  }

  const show = () => {
    sideEl.style.display = 'block'
    bodyEl.classList.add('has-l-side')
    document.querySelector('.l-side__logo-link').focus()

    setTimeout(() => {
      sideEl.classList.add('is-active')
    }, 400)
  }

  const sideNavi = e => {
    const listEls = sideEl ? sideEl.querySelectorAll('.navi-left__list-1 > li > a') : null

    if (!sideEl) {
      return
    }

    if (e.target === closeButton) {
      hide()
    }

    if (e.target === openButton) {
      show()
    }

    function hasClass(element) {
      makeArray(listEls).forEach(el => {
        if (element === el) {
          el.parentNode.classList.add('is-active')
        } else {
          el.parentNode.classList.remove('is-active')
        }
      })
    }

    makeArray(listEls).forEach(el => {
      if (el === e.target) {
        hasClass(el)
      }
    })
  }

  document.addEventListener('click', sideNavi)
})()

const isMobile = () => {
  // eslint-disable-next-line no-useless-call
  const agent = String.call(null, navigator.userAgent).toLowerCase()
  const { body } = document

  if (agent.match(/android/i)) {
    body.classList.add('aan')
  } else if (agent.match(/iphone|ipad/i)) {
    body.classList.add('aap')
  } else {
    body.classList.add('aot')
  }
}

isMobile()

// familySite
const moveFocus = (e, element, hasClass) => {
  if (e.which !== 9) {
    return
  }

  const { target } = e
  const focusEl = makeArray(element.querySelectorAll('button, a'))
  const visibileEl = focusEl.filter(el => el.offsetHeight)
  const firstEl = visibileEl[0]
  const lastEl = visibileEl[visibileEl.length - 1]

  if (e.shiftKey && target === firstEl) {
    element.classList.remove(hasClass)
  }

  if (!e.shiftKey && target === lastEl) {
    element.classList.remove(hasClass)
  }
}

const familySite = document.querySelector('.foot-family-site')
if (familySite) {
  const button = familySite.querySelector('button')

  button.addEventListener('click', () => {
    if (familySite.classList.contains('is-active')) {
      familySite.classList.remove('is-active')
    } else {
      familySite.classList.add('is-active')
      familySite.addEventListener('keydown', e => {
        moveFocus(e, familySite, 'is-active')
      })
    }
  })
}

const setLayoutInit = () => {
  let oldMenuId
  return historyState => {
    if (/^\/docs|^\/html.html/.test(window.location.pathname)) {
      return
    }

    const nowMenuId = historyState ? historyState.menuId : ''
    const yOffset = window.pageYOffset
    const flexTab = document.querySelector('.page > .c-tab')
    let { offsetHeight } = document.body
    let tid = false
    const body = document.querySelector('body')
    // const header = document.querySelector('.l-head')
    const title = document.querySelector('.l-head__title')
    const foot = document.querySelector('.l-foot')
    const footAcc = document.querySelector('#footerA-anchor-1')
    const mContents = document.querySelector('#mContents')

    if (/COM010100000000|COM010100000001|COM010100000002/.test(nowMenuId) === true) {
      body.classList.add('main')
      foot.classList.add('t-main')
      title.textContent = '푸본현대생명'

      // 메인 페이지일 경우 푸터 ARS서비스 안내 아코디언 펼침 상태로 제공
      footAcc.dispatchEvent(new CustomEvent('click'))
    } else {
      body.classList.remove('main')

      if (!foot) {
        return
      }

      foot.classList.remove('t-main')
      // header.style.opacity = 1
      // 서브 페이지일 경우 푸터 ARS서비스 닫힘 상태로 제공
      const footreAccordiion = Collapse.getInstance(foot.querySelector('#footerA-panel-1'))
      if (footreAccordiion) {
        footreAccordiion.hide()
      }
    }

    window.history.scrollRestoration = 'manual'

    if (flexTab && oldMenuId === nowMenuId) {
      const flexTabRect = flexTab.getBoundingClientRect()
      const tabTop = flexTabRect.top + yOffset
      if (yOffset >= tabTop) {
        window.scrollTo(0, tabTop + 1)
        document.body.classList.add('scroll-down')
      }
    } else {
      window.scrollTo(0, 0)
      if (nowMenuId !== 'COM010100000000') {
        document.body.classList.remove('scroll-down')
      }
    }

    function getHeight() {
      if (!tid) {
        tid = setTimeout(() => {
          tid = null
          if (offsetHeight === document.body.offsetHeight) {
            const scrollData = []
            const scrollBodyEl = document.querySelector('body:not(.main)')
            if (scrollBodyEl) {
              ScrollMove.interface(scrollBodyEl, {
                scrollDownOffset: window.innerHeight + 59,
                scrollUpOffset: document.body.offsetHeight,
                toggleClass: 'scroll-down'
              })
            }

            const tabEl = document.querySelector('.page > .c-tab')
            if (tabEl) {
              scrollData.push = new ScrollMove(tabEl, {
                scrollDownOffset: window.innerHeight,
                scrollUpOffset: window.innerHeight + 59,
                toggleClass: 'c-tab--fixed_list'
              })
            }

            const fixedButtonEls = makeArray(document.querySelectorAll('.c-button-fixed'))
            fixedButtonEls.forEach(el => {
              scrollData.push = new ScrollMove(el, {
                scrollDownOffset: 0,
                scrollUpOffset: 0,
                toggleClass: 'po-static',
                targetSelector: '.l-foot'
              })
            })

            const copyRight = makeArray(document.querySelectorAll('.foot-copyright'))
            copyRight.forEach(el => {
              ScrollMove.interface(el, {
                toggleClass: 'active',
                scrollDownOffset: 0,
                scrollUpOffset: 0
              })
            })

            if (mContents) {
              mContents.style.minHeight = 'auto'
            }

            clearTimeout(tid)
          } else {
            offsetHeight = document.body.offsetHeight
            getHeight()
          }
        }, 500)
      }
    }

    getHeight()
    if (mContents) {
      mContents.style.minHeight = '100vh'
    }

    oldMenuId = nowMenuId
  }
}

const setLayout = setLayoutInit()

// 초기화 함수
const initialize = () => {
  const checkAll = makeArray(document.querySelectorAll('[data-toggle="check-all"]'))
  checkAll.forEach(el => {
    if (!CheckAll.getInstance(el)) {
      return new CheckAll(el)
    }
  })

  const tabList = makeArray(document.querySelectorAll('.c-tab, [data-toggle="tab"]'))
  tabList.forEach(el => {
    if (!Tab.getInstance(el)) {
      return new Tab(el)
    }
  })

  const tooltipList = makeArray(document.querySelectorAll('.c-tooltip__button'))
  tooltipList.forEach(el => {
    if (!Tooltip.getInstance(el)) {
      return new Tooltip(el)
    }
  })

  const formList = makeArray(document.querySelectorAll('.c-form__field'))
  formList.forEach(el => {
    return new Form(el)
  })

  const tableList = makeArray(document.querySelectorAll('.c-table--scroll, .table-fixed__right'))
  tableList.forEach(el => {
    if (!Table.getInstance(el)) {
      return new Table(el)
    }
  })

  const LabelForList = makeArray(document.querySelectorAll('.o-input--radio, .o-input--check, .c-radio-box__input'))
  LabelForList.forEach(el => {
    return new LabelFor(el)
  })

  // search-form__input
  const searchFormInput = () => {
    const els = document.querySelectorAll('.search-form__input, .search-input__inside')
    makeArray(els).forEach(el => {
      if (el.uid) {
        return
      }

      el.uid = getUID('searchInput')
      const input = el.querySelector('input')
      const button = el.querySelector('button')

      input.addEventListener('keyup', e => {
        if (e.target.value) {
          el.classList.add('is-active')
          button.style.display = 'block'
        } else {
          el.classList.remove('is-active')
          button.style.display = 'none'
        }
      })

      button.addEventListener('click', () => {
        el.classList.remove('is-active')
        input.value = ''
        input.focus()
        button.style.display = 'none'
      })
    })

    // 인풋 넘버 타입
    const numberFormatEls = makeArray(document.querySelectorAll('[inputmode="numeric"]'))
    numberFormatEls.forEach(el => {
      el.addEventListener('keydown', e => {
        const keyId = (e.which) ? e.which : e.keyCode
        if ((keyId < 48 || keyId > 57) && (keyId !== 8)) {
          e.returnValue = false
        }
      })

      el.addEventListener('keyup', e => {
        const keyId = (e.which) ? e.which : e.keyCode
        if (keyId < 48 || keyId > 57) {
          e.target.value = e.target.value.replace(/[^0-9]/g, '')
        }

        const n = e.target.value.replace(/,/g, '')
        if (n) {
          e.target.value = n.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
          // e.target.value = new Intl.NumberFormat().format(n);
        }
      })
    })

    // 입력요소에 포커스 진입시 고정버튼 숨김처리
    // const inputEls = makeArray(document.querySelectorAll('input, textarea'))
    // inputEls.forEach(el => {
    //   el.addEventListener('focus', () => {
    //     document.body.classList.add('on-key--focus')
    //   })
    //   el.addEventListener('blur', () => {
    //     document.body.classList.remove('on-key--focus')
    //   })
    // })
  }

  const skipNavigation = () => {
    const el = document.querySelector('#skipNavigation a')
    if (!el) {
      return
    }

    el.addEventListener('click', e => {
      e.preventDefault()
      document.querySelector('#mContents').focus()
    })
  }

  const focusFixedButton = () => {
    const inputEls = makeArray(document.querySelectorAll('input, select, textarea'))

    let isFocus = false
    let fixedButton = false
    let focusInput
    let isModal = false

    const inputFocusScrollMove = () => {
      if (!fixedButton) {
        return
      }

      if (isModal) {
        if (isFocus && !fixedButton.classList.contains('d-none')) {
          fixedButton.classList.add('d-none')
        } else {
          fixedButton.classList.remove('d-none')
          isFocus = false
        }
      }

      if (!isModal) {
        if (isFocus && fixedButton.style.position === '') {
          fixedButton.style.position = 'static'
        } else {
          fixedButton.style.position = ''
          isFocus = false
        }
      }
    }

    inputEls.forEach(input => {
      if (input.matches('[type=checkbox]') || input.matches('[type=radio]') || input.eventName === 'keypad') {
        return
      }

      const handleFocus = e => {
        isFocus = true
        focusInput = e.target
        isModal = SelectorEngine.parents(focusInput, '.c-modal')[0]

        if (isModal) {
          makeArray(isModal.querySelectorAll('.c-modal__foot')).forEach(button => {
            if (button.style.display !== 'none') {
              fixedButton = button
            }
          })
        } else {
          makeArray(document.querySelectorAll('.c-button-fixed')).forEach(button => {
            if (button.offsetHeight !== 0) {
              fixedButton = button
            }
          })
        }
      }

      input.eventName = 'keypad'

      EventHandler.on(input, 'focus.keypad', handleFocus)
      EventHandler.on(input, 'click.keypad', handleFocus)
    })

    window.addEventListener('resize', inputFocusScrollMove)
  }

  searchFormInput()
  checkedModal()
  setLayout(window.history.state)
  skipNavigation()
  if (document.body.classList.contains('aan')) {
    focusFixedButton()
  }

}

export {
  initialize,
  setLayout
}
