import { makeArray, typeCheckConfig, getTransitionDurationFromElement } from './util/index.js'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import SelectorEngine from './dom/selector-engine'
import Manipulator from './dom/manipulator'

const NAME = 'table'
const VERSION = '1.0.0'
const DATA_KEY = 'fb.table'
const EVENT_KEY = `.${DATA_KEY}`

const Default = {
  // style: '(undefined|string)'
}

const DefaultType = {
  // style: 'string'
}

const ClassName = {
  SHOW: 'is-active',
  FIXED: 'table-fixed__right'
}

const Selector = {
  INFO: '.c-table__info',
  PARENT: '.c-table'
}

const Event = {
  CLICK: `click${EVENT_KEY}`
}

class Table {
  constructor(element, config) {
    this._element = element
    this._table = SelectorEngine.findOne('table', this._element)
    this._config = this._getConfig(config)

    if (this._element.offsetHeight <= 0) {
      return
    }

    if (this._element.classList.contains(ClassName.FIXED)) {
      const parent = SelectorEngine.parents(this._element, '.c-table')[0]
      this.infoEl = SelectorEngine.findOne('.c-table__info', parent)
    } else {
      this.infoEl = SelectorEngine.findOne('.c-table__info', this._element)
    }

    Data.setData(element, DATA_KEY, this)

    this.init()
    this.addListener()
  }

  // Getters

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  static get DefaultType() {
    return DefaultType
  }

  init() {
    const containerWidth = this._element.clientWidth
    const tableWidth = this._table.clientWidth
    if (containerWidth < tableWidth) {
      this.infoEl.classList.add(ClassName.SHOW)
      this._element.style.overflowX = 'hidden'
    }
  }

  addListener() {
    EventHandler.one(this.infoEl, Event.CLICK, e => {
      this.show(e)
    })
  }

  show(e) {
    e.preventDefault()
    this.infoEl.style.transition = 'opacity .5s ease-in 0s'
    this.infoEl.style.opacity = 0
    const transitionDuration = getTransitionDurationFromElement(this.infoEl)
    this.infoEl.setAttribute('aria-hidden', 'false')

    if (transitionDuration) {
      EventHandler.one(this.infoEl, 'transitionend', () => {
        this.infoEl.classList.remove('is-active')
        this._element.style.overflowX = 'auto'
      })
    }
  }

  dispose() {
    Data.removeData(this._element, DATA_KEY)
    this._element = null
  }

  // private
  _getConfig(config) {
    config = {
      ...Manipulator.getDataAttributes(this._element),
      ...config
    }

    typeCheckConfig(
      NAME,
      config,
      this.constructor.DefaultType
    )

    return config
  }

  // Static
  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

// makeArray(document.querySelectorAll('.c-table--scroll, .table-fixed__right')).forEach(el => {
//   return new Table(el)
// })

export default Table
