// 체크 박스 선택 시 모달 오픈
import { makeArray } from './util/index.js'
import SelectorEngine from './dom/selector-engine.js'

const checkedModal = () => {
  const modalToggleButtons = document.querySelectorAll('[data-toggle="modal"]')

  makeArray(modalToggleButtons).forEach(el => {
    const prevEl = SelectorEngine.prev(el, 'input')[0]
    const dataToggle = 'modal'
    const dataTarget = el.getAttribute('data-target')

    if (!prevEl) {
      return
    }

    setAttr(prevEl.checked)

    if (prevEl.matches('input')) {
      prevEl.addEventListener('click', e => {
        setAttr(e.target.checked)
      })
    }

    function setAttr(flag) {
      if (flag) {
        prevEl.setAttribute('data-toggle', '')
        prevEl.setAttribute('data-target', '')
      } else {
        prevEl.setAttribute('data-toggle', dataToggle)
        prevEl.setAttribute('data-target', dataTarget)
      }
    }
  })
}

export default checkedModal
