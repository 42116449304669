import {
  // getjQuery,
  TRANSITION_END,
  emulateTransitionEnd,
  // findShadowRoot,
  getTransitionDurationFromElement,
  // getUID,
  // isElement,
  makeArray,
  noop,
  typeCheckConfig
} from './util/index'
import Data from './dom/data'
import EventHandler from './dom/event-handler'
import Manipulator from './dom/manipulator'
import SelectorEngine from './dom/selector-engine'

const NAME = 'tooltip'
// const VERSION = '0.0.1'
const DATA_KEY = 'fb.tooltip'
const EVENT_KEY = `.${DATA_KEY}`

const DefaultType = {
  animation: 'boolean',
  trigger: 'string',
  delay: '(number|object)'
}

const Default = {
  animation: true,
  trigger: 'hover focus',
  delay: {
    show: 0,
    hide: 1000
  },
  selector: false
}

const ClassName = {
  show: 'is-active'
}

const Selector = {
  panel: '.c-tooltip__panel'
}

const Event = {
  HIDE: `hide${EVENT_KEY}`,
  HIDDEN: `hidden${EVENT_KEY}`,
  SHOW: `show${EVENT_KEY}`,
  SHOWN: `shown${EVENT_KEY}`,
  FOCUSIN: `focusin${EVENT_KEY}`,
  FOCUSOUT: `focusout${EVENT_KEY}`,
  MOUSEENTER: `mouseenter${EVENT_KEY}`,
  MOUSELEAVE: `mouseleave${EVENT_KEY}`
}

class Tooltip {
  constructor(element, config) {
    this._isEnabled = false
    this._timeout = 0
    this._hoverState = ''
    this._activeTrigger = {}

    this.config = this._getConfig(config)

    this.element = element
    this.panel = SelectorEngine.next(element, Selector.panel)[0]

    if (this.panel) {
      this.arrow = document.createElement('span')
      this.arrow.classList.add('c-tooltip__arrow')
      this.rect = this.element.getBoundingClientRect()
      this.panelRect = this.panel.getBoundingClientRect()
      const left = this.rect.x - this.panelRect.x + (this.rect.width / 2) - 7
      this.arrow.style.left = `${left}px`
      this.panel.classList.add('top')
      this.panel.appendChild(this.arrow)
    }

    Data.setData(element, DATA_KEY, this)

    this._setListeners()

    if (this.button) {
      this.init()
    }
  }

  init() {
    this.element.setAttribute('aria-describedby', `tooltip-${this.element.key.id}`)
    this.panel.setAttribute('aria-hidden', 'true')
    this.panel.setAttribute('id', `tooltip-${this.element.key.id}`)
    this.panel.setAttribute('role', 'tooltip')
  }

  toggle() {
    if (this.element.classList.contains(ClassName.show)) {
      this.hide()
    } else {
      this.show()
    }
  }

  show() {
    EventHandler.trigger(this.element, Event.SHOW)

    this.element.getAttribute('aria-hidden', 'false')

    this.panel.style.display = 'block'
    setTimeout(() => {
      this.element.classList.add(ClassName.show)
      this.setPlacement()
    }, 0)

    if (this.panel) {
      this.panelRect = this.panel.getBoundingClientRect()
      this.rect = this.element.getBoundingClientRect()
      const left = this.rect.x - this.panelRect.x + (this.rect.width / 2) - 7
      this.arrow.style.left = `${left}px`
    }

    if ('ontouchstart' in document.documentElement) {
      makeArray(document.body.children).forEach(element => {
        EventHandler.on(element, 'mouseover', noop())
      })
    }

    const buttonText = SelectorEngine.findOne('.sr-only', this.element)
    if (buttonText) {
      buttonText.textContent = buttonText.textContent.replace('열기', '닫기')
    }

    const complete = () => {
      EventHandler.trigger(this.element, Event.SHOWN)

      EventHandler.on(document, 'click.tooltip.body', e => {
        const { target } = e
        const tooltip = SelectorEngine.parents(target, '.c-tooltip__panel')[0]
        if (!tooltip) {
          this.hide()
          EventHandler.off(document, 'click.tooltip.body')
        }
      })
    }

    this._transition(complete)
  }

  hide() {
    const complete = () => {
      this.element.getAttribute('aria-hidden', 'true')
      this.panel.style.display = 'none'
      EventHandler.trigger(this.element, Event.HIDDEN)
      this._hoverState = ''
      const buttonText = SelectorEngine.findOne('.sr-only', this.element)
      if (buttonText) {
        buttonText.textContent = buttonText.textContent.replace('닫기', '열기')
      }

      this.resetPlacement()
    }

    EventHandler.trigger(this.element, Event.HIDE)

    this.element.classList.remove(ClassName.show)

    if ('ontouchstart' in document.documentElement) {
      makeArray(document.body.children)
        .forEach(element => EventHandler.off(element, 'mouseover', noop))
    }

    this._activeTrigger.focus = false
    this._activeTrigger.hover = false

    this._transition(complete)
  }

  setPlacement() {
    const { innerHeight, innerWidth } = window
    const { panel } = this
    const panelRect = panel.getBoundingClientRect()
    const offsetTop = panelRect.top + document.body.scrollTop
    const offsetLeft = panelRect.left + document.body.scrollLeft

    if (offsetTop < 0) {
      panel.classList.remove('top')
      panel.classList.add('bottom')
    } else if (offsetTop + panel.offsetHeight > innerHeight) {
      panel.classList.remove('bottom')
      panel.classList.add('top')
    }

    if (offsetLeft < 0) {
      panel.classList.add('right')
    } else if (offsetLeft + panel.offsetWidth > innerWidth) {
      panel.classList.add('left')
    }
  }

  resetPlacement() {
    const { panel } = this
    panel.classList.add('top')
    panel.classList.remove('bottom')
    panel.classList.remove('left')
    panel.classList.remove('right')
  }

  dispose() {
    Data.removeData(this._element, DATA_KEY)
    this._element = null
  }

  // Private
  _setListeners() {
    EventHandler.on(this.element, 'click', this.config.selector, event => this.toggle(event))
  }

  _enter(event) {
    if (event) {
      this._activeTrigger[
        event.type === 'focusin' ? 'focus' : 'hover'
      ] = true
    }

    if (this.element.classList.contains('is-active') || this._hoverState === 'show') {
      this._hoverState = 'show'
      return
    }

    clearTimeout(this._timeout)

    this._hoverState = 'show'

    if (!this.config.delay || !this.config.delay.show) {
      this.show()
      return
    }

    this._timeout = setTimeout(() => {
      if (this._hoverState === 'show') {
        this.show()
      }
    }, this.config.delay.show)
  }

  _leave(event) {
    if (event) {
      this._activeTrigger[
        event.type === 'focusout' ? 'focus' : 'hover'
      ] = false
    }

    if (this._isWithActiveTrigger()) {
      return
    }

    clearTimeout(this._timeout)

    this._hoverState = 'out'

    if (!this.config.delay || !this.config.delay.hide) {
      this.hide()
      return
    }

    this._timeout = setTimeout(() => {
      if (this._hoverState === 'out') {
        this.hide()
      }
    }, this.config.delay.hide)
  }

  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this.element)

    config = {
      ...Default,
      ...dataAttributes,
      ...typeof config === 'object' && config ? config : {}
    }

    if (typeof config.delay === 'number') {
      config.delay = {
        show: config.delay,
        hide: config.delay
      }
    }

    typeCheckConfig(
      NAME,
      config,
      DefaultType
    )

    return config
  }

  _isWithActiveTrigger() {
    for (const trigger in this._activeTrigger) {
      if (this._activeTrigger[trigger]) {
        return true
      }
    }

    return false
  }

  _transition(fn) {
    const transitionDuration = getTransitionDurationFromElement(this.panel)
    if (transitionDuration) {
      EventHandler.one(this.panel, TRANSITION_END, fn)
      emulateTransitionEnd(this.panel, transitionDuration)
    } else {
      fn()
    }
  }

  // Static
  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

const tooltipTriggerList = [].slice.call(document.querySelectorAll('.c-tooltip__button'))
tooltipTriggerList.map(el => {
  return new Tooltip(el)
})

export default Tooltip
