import Data from './dom/data.js'
import EventHandler from './dom/event-handler.js'
import Manipulator from './dom/manipulator.js'

const NAME = 'scrollmove'
const VERSION = '1.0.0'
const DATA_KEY = 'fb.scrollmove'
const EVENT_KEY = `.${DATA_KEY}`

const Default = {
  speed: 0.5
}

const Event = {
  SCROLL: `scroll${EVENT_KEY}`
}

class ScrollMove {
  constructor(element, config) {
    this._element = element
    this._config = this._getConfig(config)
    this._config.delay = this._config.delay || 0
    this.direction = false
    this.lastScrollTop = window.pageYOffset


    Data.setData(element, DATA_KEY, this)
    this.addListener()
  }

  update() {
    this.lastScrollTop = window.pageYOffset
  }

  addListener() {
    EventHandler.on(window, Event.SCROLL, () => {
      this.top = window.pageYOffset + this._element.getBoundingClientRect().top
      if (this._config.targetSelector) {
        const el = document.querySelector(this._config.targetSelector)
        this.top = el ? window.pageYOffset + el.getBoundingClientRect().top : this.top
      }

      const windowHeight = window.innerHeight
      const bodyHeight = document.body.offsetHeight
      const maxScrollValue = bodyHeight - windowHeight
      const scrollOffset = maxScrollValue - (bodyHeight - this.top)
      this.downOffset = scrollOffset + this._config.scrollDownOffset || 0
      this.upOffset = scrollOffset + this._config.scrollUpOffset || 0
      const y = window.pageYOffset
      if (y <= 0) {
        this.lastScrollTop = 0
        return
      }

      if (this.lastScrollTop > window.pageYOffset) {
        this.direction = 'up'
      } else {
        this.direction = 'down'
      }

      if (y >= this.downOffset && this.direction === 'down' && !this._element.classList.contains(this._config.toggleClass)) {
        this._element.classList.add(this._config.toggleClass)
        if (this._config.downCallback) {
          this._config.downCallback(this)
        }
      }

      if (y <= this.upOffset && this.direction === 'up' && this._element.classList.contains(this._config.toggleClass)) {
        this._element.classList.remove(this._config.toggleClass)
      }

      this.lastScrollTop = window.pageYOffset
    })
  }

  // getter
  static get NAME() {
    return NAME
  }

  static get VERSION() {
    return VERSION
  }

  static get Default() {
    return Default
  }

  // private
  _getConfig(config) {
    const dataAttributes = Manipulator.getDataAttributes(this._element)

    config = {
      ...this.constructor.Default,
      ...dataAttributes,
      ...typeof config === 'object' && config ? config : {}
    }

    return config
  }

  static interface(element, config) {
    let data = Data.getData(element, DATA_KEY)

    if (data) {
      data.update()
    } else {
      data = new ScrollMove(element, config)
    }
  }

  static getInstance(element) {
    return Data.getData(element, DATA_KEY)
  }
}

export default ScrollMove
